.shortcutButton {
  &,
  span {
    transition: all 250ms ease-in-out;
  }

  &:hover {
    transform: scale(1.02);

    span:first-child {
      color: theme('colors.secondary');
    }

    span:last-child {
      transform: scale(1.1);
    }
  }
}
